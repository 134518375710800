import type { Theme, ThemeOptions } from '@mui/material';
import { SquareInfoIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/SquareInfoIcon';
import { SquareTickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/SquareTickIcon';
import { SquareWarningIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/SquareWarningIcon';
import { LinkBehavior } from '@noah-labs/fe-shared-ui-components';

const inputPadLeft = 2;
const labelMargin = '2px';

export function getWebComponents(theme: Theme): ThemeOptions['components'] {
  return {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: SquareWarningIcon({}),
          info: SquareInfoIcon({}),
          success: SquareTickIcon({}),
          warning: SquareWarningIcon({}),
        },
      },
      styleOverrides: {
        icon: {
          fontSize: '1.5rem',
          lineHeight: 1,
          marginRight: theme.spacing(1),
          opacity: 1,
          padding: 0,
          position: 'relative',
          svg: {
            fontSize: 'inherit',
          },
          // small adjustment to better align icon
          top: '-1px',
        },
        message: {
          alignSelf: 'center',
          flexGrow: 1,
          lineHeight: 1.4,
          padding: 0,
        },
        root: {
          ...theme.typography.paragraphBodyS,
          borderRadius: theme.borderRadius.md,
          color: theme.palette.text.primary,
          padding: theme.spacing(1.5, 2),
        },
        standardError: {
          '.MuiAlert-icon': {
            color: theme.palette.error.main,
          },
          backgroundColor: theme.palette.error.light,
          border: `1px solid ${theme.palette.error.main}`,
        },
        standardInfo: {
          '.MuiAlert-icon': {
            color: theme.palette.info.dark,
          },
          backgroundColor: theme.palette.info.light,
          border: `1px solid ${theme.palette.info.main}`,
        },
        standardSuccess: {
          '.MuiAlert-icon': {
            color: theme.palette.success.dark,
          },
          backgroundColor: theme.palette.success.light,
          border: `1px solid ${theme.palette.success.main}`,
        },
        standardWarning: {
          '.MuiAlert-icon': {
            color: theme.palette.warning.dark,
          },
          backgroundColor: theme.palette.warning.light,
          border: `1px solid ${theme.palette.warning.main}`,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          ...theme.typography.paragraphBodyMBold,
          marginTop: 0,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.backdrop,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorError: {
          color: theme.palette.common.white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // overriding default MUI styles for startIcon and endIcon to match with expected SvgIcon sizing
        iconSizeMedium: {
          '& > *:nth-of-type(1)': {
            fontSize: '1.5rem',
          },
        },
        root: {
          borderRadius: theme.borderRadius.cylinder,
        },
        sizeLarge: {
          ...theme.typography.buttonL,
          minHeight: theme.spacing(6.5),
          padding: theme.spacing(2, 4),
        },
        sizeMedium: {
          ...theme.typography.buttonM,
          minHeight: theme.spacing(5.5),
          padding: theme.spacing(1.75, 3.5),
        },
        sizeSmall: {
          ...theme.typography.buttonS,
          minHeight: theme.spacing(4.5),
          padding: theme.spacing(1, 2),
        },
        startIcon: {
          marginLeft: 0,
        },
      },
      variants: [
        ...(theme.components?.MuiButton?.variants ?? []),
        {
          // Error button
          props: { color: 'error', variant: 'contained' },
          style: {
            color: theme.palette.grayscale.white,
          },
        },
        {
          // OnlyIconButton component
          props: { size: 'mediumIcon' },
          style: {
            minWidth: 'initial',
            padding: theme.spacing(1.5),
          },
        },
        {
          // Button component XS size
          props: { size: 'xs' },
          style: {
            ...theme.typography.paragraphBodyS,
            '.MuiButton-endIcon': {
              marginLeft: theme.spacing(0.5),
              marginRight: theme.spacing(0),
              svg: {
                fontSize: 'inherit',
              },
            },
            padding: theme.spacing(0.5, 1),
          },
        },
        {
          // Address component
          props: { color: 'input', size: 'medium', variant: 'contained' },
          style: {
            border: `1px solid ${theme.palette.input.borderNormal}`,
            padding: theme.spacing(1.5, 2),
            textTransform: 'none',
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior, // See: https://next.material-ui.com/guides/routing/#global-theme-link
      },
      styleOverrides: {
        root: {
          // needed otherwise it uses Arial for icon buttons
          fontFamily: theme.typography.fontFamily,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        raised: true,
      },
      styleOverrides: {
        root: {
          borderRadius: theme.borderRadius.md,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: theme.spacing(2),
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: theme.palette.primary.dark,
          },
          color: theme.palette.input.background,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '&.MuiDialog-paperFullScreen': {
            borderRadius: 0,
          },
          [theme.breakpoints.up('lg')]: {
            alignSelf: 'center',
            borderRadius: theme.borderRadius.lg,
          },
          alignSelf: 'flex-end',
          backgroundColor: theme.palette.grayscale.offWhite,
          borderRadius: `${theme.borderRadius.sm} ${theme.borderRadius.sm} 0 0`,
          margin: 0,
          /* position and z-index are needed because of this safari bug: https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari  */
          position: 'relative',
          width: '100%',
          zIndex: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 2, 2),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: theme.palette.text.light,
          // allow box shadows to spill downwards into the CTA area
          overflowY: 'visible',
          padding: theme.spacing(2, 2, 4),
        },
      },
    },
    MuiDialogContentText: {
      defaultProps: {
        color: 'text.light',
        variant: 'paragraphBodyM',
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...theme.typography.subHeadingM,
          padding: theme.spacing(2, 2, 1),

          // only pay attention to `\n` when in mobile mode
          [theme.breakpoints.down('sm')]: {
            whiteSpace: 'pre-wrap',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: theme.palette.grayscale[400],
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: theme.palette.grayscale[200],
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          borderRadius: theme.borderRadius.cylinder,
          padding: theme.spacing(1.75, 0),
        },
        root: {
          '&.MuiInputBase-multiline': {
            backgroundColor: theme.palette.input.background,
            borderRadius: theme.borderRadius.md,
          },
          borderRadius: theme.borderRadius.cylinder,
          padding: theme.spacing(0, 2),
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        labelPlacementStart: {
          '.MuiCheckbox-root, .MuiRadio-root': {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(-1),
          },
        },
        root: {
          '.MuiCheckbox-root, .MuiRadio-root': {
            marginLeft: theme.spacing(-1),
          },
          display: 'flex',
          margin: 0,
          width: '100%',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...theme.typography.paragraphBodyS,
          marginLeft: theme.spacing(inputPadLeft),
          marginTop: labelMargin,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          ...theme.typography.paragraphBodyM,
          '&:-webkit-autofill, &:-internal-autofill-selected': {
            background: 'inherit',
            caretColor: 'auto',
            color: 'inherit',
            WebkitBoxShadow: 'none',
            WebkitTextFillColor: 'inherit',
          },
          // Needs height auto as MUI is setting a fixed height
          height: 'auto',
        },
        multiline: {
          '.MuiOutlinedInput-input': {
            borderRadius: theme.borderRadius.md,
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: theme.borderRadius.md,
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        // Setting shrink to 'true' enables the placeholders
        shrink: true,
      },
      styleOverrides: {
        outlined: {
          marginBottom: labelMargin,
          position: 'initial',
          transform: 'none',
        },
        root: {
          '&.Mui-focused': {
            color: theme.palette.text.primary,
          },
          ...theme.typography.paragraphBodyS,
          paddingLeft: theme.spacing(inputPadLeft),
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: theme.palette.text.link,
        // See: https://next.material-ui.com/guides/routing/#global-theme-link
        component: LinkBehavior,
        underline: 'none',
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          flex: '0 0 auto',
          marginRight: theme.spacing(1.25),
          minWidth: 'initial',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          // Mui puts an opaque color on this normally
          color: 'initial',
          marginRight: theme.spacing(1.25),
          minWidth: 'initial',
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        input: {
          borderRadius: theme.borderRadius.cylinder,
          padding: theme.spacing(1.75, 0),
        },
        notchedOutline: {
          borderColor: theme.palette.input.background,
          borderRadius: theme.borderRadius.cylinder,
        },
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
          },
          '&.MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline, :hover .MuiOutlinedInput-notchedOutline':
            {
              borderColor: theme.palette.input.borderActive,
            },
          '&.MuiInputBase-multiline': {
            borderRadius: theme.borderRadius.md,
          },
          backgroundColor: theme.palette.input.background,
          borderRadius: theme.borderRadius.cylinder,
          padding: theme.spacing(0, 2),
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          borderColor: theme.palette.grayscale[300],
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: theme.palette.primary.dark,
          },
          color: theme.palette.input.background,
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: theme.palette.grayscale[500],
          marginRight: theme.spacing(1),
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        text: {
          backgroundColor: theme.palette.grayscale[100],
        },
      },
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'xsmall' },
          style: {
            fontSize: '1rem',
          },
        },
      ],
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          display: 'flex',
          height: theme.shape.switch.height,
          padding: 0,
          width: theme.shape.switch.width,
        },
        switchBase: {
          '&.Mui-checked': {
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.brand.main,
              opacity: 1,
            },
            color: theme.palette.common.white,
            transform: `translateX(calc(${theme.shape.switch.width} - ${theme.shape.switch.height}))`,
          },
          padding: '2px',
        },
        thumb: {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          height: `calc(${theme.shape.switch.height} - 4px})`,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
          width: `calc(${theme.shape.switch.height} - 4px})`,
        },
        track: {
          backgroundColor: theme.palette.secondary.main,
          borderRadius: `calc(${theme.shape.switch.width} / 2)`,
          boxSizing: 'border-box',
          opacity: 1,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '1px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      variants: [
        {
          props: { size: 'primaryAmount' },
          style: {
            '.MuiFormHelperText-root': {
              ...theme.typography.paragraphBodyS,
              textAlign: 'center',
            },
            '.MuiInputBase-input': {
              ...theme.typography.headingXL,
            },
            '.MuiInputBase-root': {
              ...theme.typography.headingXL,
              '&::after': {
                borderColor: theme.palette.input.borderActive,
              },

              '&::before': {
                borderColor: theme.palette.input.borderNormal,
              },
              justifyContent: 'center',
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            '.MuiFormHelperText-root': {
              marginLeft: theme.spacing(2.25),
            },
            '.MuiInputBase-input': {
              padding: theme.spacing(2.25, 0),
            },
            '.MuiInputLabel-root': {
              paddingLeft: theme.spacing(2.25),
            },
            '.MuiOutlinedInput-root': {
              padding: theme.spacing(0, 2.25),
            },
          },
        },
      ],
    },
  };
}
